//take a json  and download it as a csv file
export default function downloadCSV(obj, filename) {
  let csv = "";

  let json = obj;
  let frameHistory = [];

  if (!Array.isArray(obj)) {
    json = [obj];
  }

  console.log("json", json);

  json.map((row) => {
    let data = row.data;
    //parse json data
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch (e) {
        //do nothing
      }
    }

    //find any keys called geocode and escape the commas
    Object.keys(data).forEach((key) => {
      if (key.toLowerCase().includes("geocode")) {
        data[key] = data[key].replace(/,/g, " ");
      }
    });

    //get time created from row and add to data
    data.time_created = row.time_created;

    //add data to row
    frameHistory.push(data);
    return frameHistory;
  });

  //get keys from frame history
  let keys = [];
  frameHistory.forEach((frame) => {
    Object.keys(frame).forEach((key) => {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
  });

  //remove key if it matches "dont_log_frame"
  keys = keys.filter((key) => key !== "dont_log_frame");

  //download csv
  csv += keys.join(",") + "\n";
  frameHistory.forEach((frame) => {
    let row = [];
    keys.forEach((key) => {
      if (frame[key]) {
        //if its an array, use first element
        if (Array.isArray(frame[key])) {
          row.push(frame[key][0]);
          return row;
        } else {
          row.push(frame[key]);
          return row;
        }
      } else {
        row.push("");
      }
      return row;
    });
    csv += row.join(",") + "\n";

    return csv;
  });

  let blob = new Blob([csv], { type: "text/csv" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
