export default function formatSpeed(speed) {
  let unit = localStorage.getItem("speed_unit") || "kmph";
  let unitText = unit === "kmph" ? "km/h" : "mph";

  //check if localStorage has speed_unit
  if (unit === "mph") {
    speed = speed * 0.621371;
  }

  //round speed to 2 decimal places
  speed = Math.round(speed * 100) / 100;

  return speed + " " + unitText;
}
