//copy of device page with no auth and using the share api to fetch the data

import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { CONFIG } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import NoDeviceFoundDialog from "../components/Dialogs/NoDeviceFound";
import DateTimePickerDialog from "../components/Dialogs/DateTimePicker";
import moment from "moment";
import addHistoryMarkers from "../Functions/addHistoryMarkers";
import addCurrentLocation from "../Functions/addCurrentLocation";
import addPolyline from "../Functions/addPolyline";
import { useSelector } from "react-redux";

import { Container, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { minMaxLngLat } from "../Functions/minMaxLngLat";

import AirQuality from "../components/DeviceTypes/AirQuality";
import TempHumidity from "../components/DeviceTypes/TempHumidity";
import PeopleCounter from "../components/DeviceTypes/PeopleCounter";
import Temperature from "../components/DeviceTypes/Temperature";
import MotionWithTemp from "../components/DeviceTypes/MotionWithTempHumidity";
import DoorWindowWithTemp from "../components/DeviceTypes/DoorWindowWithTemp";
import WaterLeakTempHumidity from "../components/DeviceTypes/WaterLeakTempHumidity";
import AirQualityTVOC from "../components/DeviceTypes/AirQualityTVOC";
import Distance from "../components/DeviceTypes/Distance";
import { store } from "../store";
import isTracker from "../Functions/isTracker";
import setTitle from "../Functions/setTitle";
import PetTracker from "../components/DeviceTypes/PetTracker";
import Oyster3 from "../components/DeviceTypes/Oyster3";
import TrackerWithEvent from "../components/DeviceTypes/TrackerWithEvent";
import CayenneLPP from "../components/DeviceTypes/CayenneLPP";
import TektelicVivid from "../components/DeviceTypes/TektelicVivid";
import Distance2 from "../components/DeviceTypes/Distance2";
import Power from "../components/DeviceTypes/Power";
import WaterLeakNBIOT from "../components/DeviceTypes/WaterLeak";
import IndoorAirQualityNBIOT from "../components/DeviceTypes/IndoorAirQualityNBIOT";
import TempHumidityNbIoT from "../components/DeviceTypes/TempHumidityNBIoT";
import DryContact from "../components/DeviceTypes/DryContact";
import RobSound from "../components/DeviceTypes/RobSound";
import WaterMeterSensecore from "../components/DeviceTypes/WaterMeterSensecore";
import MotionNBIOT from "../components/DeviceTypes/MotionNBIOT";
import SidebarShareMap from "../components/sidebarShareMap";
import WaterFlow from "../components/DeviceTypes/WaterFlow";
import Rak4Button from "../components/DeviceTypes/Rak4Button";

// eslint-disable-next-line
import Lsn50v2 from "../components/DeviceTypes/lsn50v2_d20";
import LHT52 from "../components/DeviceTypes/LHT52";
import DoorWindow from "../components/DeviceTypes/DoorWindow";
import GoIoTwiskaairhumiditypmqos from "../components/DeviceTypes/GoIoTwiskaairhumiditypmqos";
import DraginoLES01 from "../components/DeviceTypes/DraginoLSE01";
import DraginoLsph01 from "../components/DeviceTypes/DraginoLsph01";
import DraginoLlms01 from "../components/DeviceTypes/DraginoLlms01";
import DraginoLSN50V2D20 from "../components/DeviceTypes/Dragino-LSN50V2_d20";
import DraginoLSN50v2S31 from "../components/DeviceTypes/DraginoLSN50v2S31";
import VutilityHotdrop from "../components/DeviceTypes/VutilityHotdrop";
import CustomPressure01 from "../components/DeviceTypes/CustomPressure";
import DraginoLDS03A from "../components/DeviceTypes/DraginoLDS03A";
/*
TODO:

- check if owner of device is paid up, if not, show device inactive warning to let the billing owner know they need to pay.
*/

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */

export default function Share(props) {
  const mapRef = useRef(null);
  const [NoDeviceFound, setNoDeviceFound] = useState(false);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [deviceData, setDeviceData] = useState({}); // eslint-disable-line no-unused-vars
  const isMobile = window.innerWidth <= 500;
  const [nonTracker, setNonTracker] = useState(false);
  const [deviceType, setDeviceType] = useState(0);
  const params = useParams();
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const dateRange = useSelector((state) => state.dateRange);

  const [hasDetails, setHasDetails] = useState(false);

  const theme = useTheme();

  const updateHistoryInterval = useRef(null);

  const getDeviceHistory = useCallback(
    async (startDate, endDate) => {
      if (startDate === undefined || endDate === undefined) {
        startDate = moment().local().subtract(1, "day").toISOString();
        endDate = moment().local().endOf("day").toISOString();

        startDate = moment(startDate).unix();
        endDate = moment(endDate).unix();
      }

      try {
        //axois get with auth0 token header
        const response = await axios.get(
          CONFIG.API_URL + `/device/share/${params.id}/${startDate}/${endDate}`
        );

        let history = response.data;

        //sort history by time_created ordest to newest
        history.sort((a, b) => {
          return new Date(a.time_created) - new Date(b.time_created);
        });

        //if isTracker device.device_type filter out any history where last_data latitude and longitude are 0
        //remove any history where last_data (encoded json) latitude or longitude is null or 0
        if (isTracker(deviceType)) {
          history = history.filter((point) => {
            let data = point.data;
            data = JSON.parse(data);

            if (data.latitude === null || data.longitude === null) {
              return false;
            }

            if (data.latitude === 0 || data.longitude === 0) {
              return false;
            }

            return true;
          });
        }
        store.dispatch({
          type: "deviceHistory/update",
          payload: history,
        });

        return history;
      } catch (e) {
        if (!e.response) {
          console.log(e);
          return [];
        }
        //if 404 show no device found dialog
        if (e.response.status === 404) {
          setNoDeviceFound(true);
        }
        console.log(e);
        return [];
      }
    },
    [deviceType, params.id]
  );

  const createMap = useCallback(async () => {
    if (mapRef.current) {
      return;
    }
    let history = await getDeviceHistory();
    let deviceProfile = await axios.get(
      CONFIG.API_URL + `/device/share/profile/${params.id}`
    );

    if (deviceProfile.data) {
      if (deviceProfile.data.profile) {
        deviceProfile = deviceProfile.data.profile;

        if (typeof deviceProfile === "string") {
          deviceProfile = JSON.parse(deviceProfile);
        }
      }
    }

    let coordinates = [];

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      coordinates.push(minMaxLngLat([data.longitude, data.latitude]));
    });

    mapRef.current = new mapboxgl.Map({
      container: "map-share",
      style: CONFIG.mapStyle,
    });

    //add controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
    //add fullscreen
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-left");
    //add scale bottom right
    mapRef.current.addControl(new mapboxgl.ScaleControl(), "bottom-right");

    class DateTimePickerToggle {
      onAdd(map) {
        this._map = map;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-datePicker";
        this._btn.title = "Select Time Period";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          setOpenDateTimePicker(!openDateTimePicker);
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    const dateTimePickerToggle = new DateTimePickerToggle();

    mapRef.current.addControl(dateTimePickerToggle, "top-left");

    mapRef.current.on("load", () => {
      if (history.length > 0) {
        addPolyline(mapRef.current, history, deviceProfile);
        addHistoryMarkers(mapRef.current, history);
        addCurrentLocation(mapRef.current, history);

        let bounds = new mapboxgl.LngLatBounds();
        coordinates.forEach((point) => {
          bounds.extend(point);
        });

        //fit the map to the bounds
        mapRef.current.fitBounds(bounds, {
          padding: isMobile ? 50 : 20,
          maxZoom: 17,
          essential: true,
          duration: 0,
          animated: false,
        });
      }
    });
  }, [getDeviceHistory, isMobile, openDateTimePicker, params.id]);

  const updateHistorySource = useCallback(async () => {
    //get the history
    setOpenDateTimePicker(false);

    let history = await getDeviceHistory(
      dateRange.startDate,
      dateRange.endDate
    );

    if (history.length === 0) {
      return;
    }

    addPolyline(mapRef.current, history);
    addHistoryMarkers(mapRef.current, history);
    addCurrentLocation(mapRef.current, history);

    let bounds = new mapboxgl.LngLatBounds();

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      bounds.extend(minMaxLngLat([data.longitude, data.latitude]));
    });

    //fit the map to the bounds
    mapRef.current.fitBounds(bounds, {
      padding: isMobile ? 50 : 200,
      maxZoom: 15,
    });
  }, [getDeviceHistory, dateRange, isMobile]);

  async function checkIfContainsDetails(history) {
    if (history.length === 0) {
      setHasDetails(false);
      return;
    }
    //check if data contains temperature, temp, or temp_internal
    let containsDetails = false;

    let lastData = history[history.length - 1];
    let lastDataJson = JSON.parse(lastData.data);

    if (
      lastDataJson.temperature ||
      lastDataJson.temp ||
      lastDataJson.temp_internal
    ) {
      containsDetails = true;
    }

    setHasDetails(containsDetails);
  }

  const getDevice = useCallback(async () => {
    try {
      //axois get with auth0 token header
      const response = await axios.get(
        CONFIG.API_URL + `/device/share/info/${params.id}`
      );

      setDeviceData(response.data);

      let resDeviceProfile = await axios.get(
        CONFIG.API_URL + `/device/share/profile/${params.id}`
      );

      if (resDeviceProfile.data) {
        if (resDeviceProfile.data.profile) {
          resDeviceProfile = resDeviceProfile.data.profile;

          if (typeof resDeviceProfile === "string") {
            resDeviceProfile = JSON.parse(resDeviceProfile);
          }

          store.dispatch({
            type: "deviceProfile/update",
            payload: resDeviceProfile,
          });
        }
      }

      setTitle(
        response.data && response.data.name
          ? response.data.name
          : "Share Device"
      );

      setDeviceType(response.data.device_type);

      //if device_type = 1, 4, 7 then create map
      if (isTracker(response.data.device_type)) {
        createMap();
      } else {
        setNonTracker(true);
        getDeviceHistory();
      }
    } catch (e) {
      if (!e.response) {
        console.log(e);
        return [];
      }
      //if 404 show no device found dialog
      if (e.response.status === 404) {
        setNoDeviceFound(true);
      }
      console.log(e);
      return [];
    }
  }, [getDeviceHistory, params.id, createMap]);

  //use effect to fetch the data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getDevice();

      //start the interval to update the history every 60 seconds
      updateHistoryInterval.current = setInterval(() => {
        getDeviceHistory();
      }, 60000);
    }

    return () => {
      mounted = false;
      clearInterval(updateHistoryInterval.current);
    };
  }, [getDevice, getDeviceHistory]);

  useEffect(() => {
    if (mapRef.current) {
      updateHistorySource();
    }
  }, [dateRange, updateHistorySource]);

  useEffect(() => {
    if (isTracker(deviceType)) {
      checkIfContainsDetails(deviceHistory);
    }
  }, [deviceHistory, deviceType]);

  if (nonTracker === false) {
    return (
      <Container
        maxWidth="false"
        disableGutters
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#121212" : "#EFEFEF",
          minHeight: "100vh",
          padding: hasDetails ? 1 : 0,
        }}
      >
        <div
          id="map-share"
          className={hasDetails ? "map-details" : "map-nodetails"}
        ></div>
        <SidebarShareMap />

        <NoDeviceFoundDialog open={NoDeviceFound} />
        <DateTimePickerDialog
          open={openDateTimePicker}
          onClose={() => setOpenDateTimePicker(false)}
          trackerStartDate={dateRange.startDate}
          trackerEndDate={dateRange.endDate}
        />
      </Container>
    );
  } else {
    return (
      <Container
        //fullwidth, with theme.pallete.mode aware background color
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#121212" : "#EFEFEF",
          minHeight: "100vh",
          //padding left and right 1
          px: 1,
        }}
        maxWidth="false"
        disableGutters
      >
        <Typography
          variant="h4"
          sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000", pt: 1 }}
        >
          {deviceData.name}
        </Typography>
        {deviceType === 2 && (
          //air quality
          <AirQuality isShare />
        )}
        {deviceType === 3 && (
          //Temp Humidity
          <TempHumidity isShare />
        )}
        {deviceType === 5 && (
          //Distance Sensor
          <Distance isShare />
        )}
        {deviceType === 6 && (
          //motion sensor with temp and humidity
          <MotionWithTemp isShare />
        )}
        {deviceType === 8 && (
          //pax counter
          <PeopleCounter isShare />
        )}
        {deviceType === 9 && (
          //temperature
          <Temperature isShare />
        )}
        {deviceType === 10 && (
          //motion with temp
          <MotionWithTemp isShare />
        )}
        {deviceType === 11 && (
          //door/window sensor with temp
          <DoorWindowWithTemp isShare />
        )}
        {deviceType === 12 && (
          //water leak with temp humidity
          <WaterLeakTempHumidity isShare />
        )}
        {deviceType === 13 && (
          //air quality tvoc
          <AirQualityTVOC isShare />
        )}
        {deviceType === 14 && (
          //pet tracker
          <PetTracker isShare />
        )}
        {deviceType === 15 && (
          //oyster 3
          <Oyster3 isShare />
        )}
        {deviceType === 16 && (
          //tracker with event
          <TrackerWithEvent isShare />
        )}
        {deviceType === 17 && (
          //cayenne lpp
          <CayenneLPP isShare />
        )}
        {deviceType === 18 && (
          //tektelic vivid
          <TektelicVivid isShare />
        )}
        {deviceType === 19 && (
          //water meter sensecore
          <WaterMeterSensecore isShare />
        )}
        {deviceType === 20 && (
          //distance 2
          <Distance2 isShare />
        )}
        {deviceType === 21 && (
          //power
          <Power isShare />
        )}
        {deviceType === 22 && (
          //water leak nbiot
          <WaterLeakNBIOT isShare />
        )}
        {deviceType === 23 && (
          //indoor air quality nbiot
          <IndoorAirQualityNBIOT isShare />
        )}
        {deviceType === 24 && (
          //temp humidity nbiot
          <TempHumidityNbIoT isShare />
        )}
        {deviceType === 25 && (
          //dry contact
          <DryContact isShare />
        )}
        {deviceType === 26 && (
          //distance nbiot
          <MotionNBIOT isShare />
        )}
        {deviceType === 27 && (
          //rob sound
          <RobSound isShare />
        )}
        {deviceType === 28 && (
          //water flow
          <WaterFlow isShare />
        )}
        {deviceType === 29 && (
          //rak4 button
          <Rak4Button isShare />
        )}
        {deviceType === 30 && (
          //lsn50v2_d23
          <Lsn50v2 isShare />
        )}
        {deviceType === 31 && (
          //lht52
          <LHT52 isShare />
        )}
        {deviceType === 32 && (
          //door window
          <DoorWindow isShare />
        )}
        {deviceType === 33 && (
          //door window
          <GoIoTwiskaairhumiditypmqos isShare />
        )}
        {deviceType === 34 && (
          //dragino lse01
          <DraginoLES01 isShare />
        )}
        {deviceType === 35 && (
          //dragino lsph01
          <DraginoLsph01 isShare />
        )}
        {deviceType === 36 && (
          //dragino llms01
          <DraginoLlms01 isShare />
        )}
        {deviceType === 37 && <DraginoLSN50V2D20 isShare />}
        {deviceType === 38 && <DraginoLSN50v2S31 isShare />}
        {deviceType === 39 && <VutilityHotdrop isShare />}
        {deviceType === 40 && <CustomPressure01 isShare />}
        {deviceType === 41 && <DraginoLDS03A isShare />}
      </Container>
    );
  }
}
