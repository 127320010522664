import { useParams } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { Button, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Add, Lock, LockOpen, Save, Settings } from "@mui/icons-material";
import fetchDashboardDevices from "../Functions/fetchDashboardDevices";
import AddWidgetDialog from "../components/Dialogs/AddWidget";
import ReactGridLayout from "react-grid-layout";

//include these css files for react-grid-layout
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DashboardWidget from "../components/Widgets/DashboardWidget";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import SaveDashboard from "../Functions/DashboardSave";
import { fetchDashboards } from "../Functions/fetchDashboards";
import DashboardShare from "../components/Dialogs/DashboardShare";
import DeleteDashboardConfirmationDialog from "../components/Dialogs/DashboardDelete";
import DashboardEditDialog from "../components/Dialogs/dashboardEdit";

export default function Dashboard(props) {
  //get id from url
  const { id } = useParams();
  const theme = useTheme();

  const dashboards = useSelector((state) => state.dashboards);
  const isMobile = useSelector((state) => state.isMobile);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const [dashboard, setDashboard] = useState({});

  const [dashboardDevices, setDashboardDevices] = useState([]);
  const [dashboardWidgets, setDashboardWidgets] = useState([]);
  const [addWidgetOpen, setAddWidgetOpen] = useState(false);
  const [layout, setLayout] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dashboardOptionsOpen = Boolean(anchorEl);

  const [deleteDashboardDialogOpen, setDeleteDashboardDialogOpen] =
    useState(false);
  const [editDashboardDialogOpen, setEditDashboardDialogOpen] = useState(false);

  const [openShare, setOpenShare] = useState(false);

  /*const script = document.createElement("script");
  script.type = "text/javascript";
  script.innerHTML = `window.agentOptions = {
    agent_id: 'bba799c4-ccac-4fd3-b134-091d392f8941'
};`;
  document.body.appendChild(script);

  const script2 = document.createElement("script");
  script2.src =
    "https://tingkartcopilot.azurewebsites.net/agent/8f5a2aff-0d2f-4f04-be97-a4a3311bc9c0.js";
  script2.crossOrigin = "anonymous";
  document.body.appendChild(script2);
  */

  const handleDashboardOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleEditDashboard = () => {
    setEditDashboardDialogOpen(!editDashboardDialogOpen);
  };

  const handleDeleteDashboard = () => {
    setDeleteDashboardDialogOpen(!deleteDashboardDialogOpen);
  };

  const handleOpenShareDialog = () => {
    setOpenShare(!openShare);
  };

  const [editMode, setEditMode] = useState(false);

  let width = window.innerWidth - 15;

  if (!isMobile) {
    width = props.collapsed ? window.innerWidth - 100 : window.innerWidth - 270;
  }

  //when the window is resized, update the width of the grid
  window.onresize = () => {
    width = window.innerWidth - 15;
  };

  const handleAddWidgetClose = () => {
    setAddWidgetOpen(false);
    //wait 1 second and then fetch the dashboard again
    setTimeout(() => {
      fetchDashboards(selectedOrganisation.id);
    }, 1000);
  };

  const saveState = () => {
    SaveDashboard(layout, id);
    updateSnackbarMessage("Success: Layout saved");
  };

  useEffect(() => {
    if (!dashboards.length) {
      return;
    }
    async function fetchDevices() {
      const devices = await fetchDashboardDevices(id);

      if (!devices) {
        return;
      }

      setDashboardDevices(devices);
    }

    let dashboardFind = dashboards.find(
      (dashboard) => parseInt(dashboard.id) === parseInt(id)
    );

    if (dashboardFind) {
      setDashboard(dashboardFind);
      setDashboardDevices(dashboardFind.devices);
      setDashboardWidgets(dashboardFind.widgets);

      fetchDevices();
    } else {
      //redirect to the dashboard page
      window.location.href = "/dashboard";
    }

    //set an interval to fetch the devices every minute
    const interval = setInterval(() => {
      fetchDevices();
    }, 60000);

    return () => clearInterval(interval);
  }, [dashboards, id]);

  const refreshDashboard = () => {
    fetchDashboards(selectedOrganisation.id);
  };

  return (
    <>
      <ReactGridLayout
        className="layout"
        rowHeight={30}
        width={width}
        isDraggable={editMode}
        isResizable={editMode}
        onLayoutChange={(layout) => {
          setLayout(layout);
        }}
      >
        <div
          key="pageHeader"
          data-grid={{
            x: 0,
            y: 0,
            w: 12,
            h: 1.7,
            static: true,
          }}
          className="widget"
        >
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <PageTitle
              theme={theme}
              title={dashboard && dashboard.name ? dashboard.name : "Dashboard"}
              subTitle={
                dashboard && dashboard.description
                  ? dashboard.description
                  : "Dashboard"
              }
            />
            {
              //add widget button
            }
            {editMode ? (
              <Tooltip title="Save Layout" placement="top" arrow>
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  sx={{ mr: 1 }}
                  onClick={() => saveState()}
                >
                  <Save />
                </Button>
              </Tooltip>
            ) : null}
            {!editMode ? (
              <Tooltip title="Add a Widget" placement="top" arrow>
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  sx={{ mr: 1 }}
                  onClick={() => setAddWidgetOpen(true)}
                >
                  <Add />
                </Button>
              </Tooltip>
            ) : null}
            <Tooltip
              title={editMode ? "Lock Dashboard" : "Unlock Dashboard"}
              placement="top"
              arrow
            >
              {
                //slider for edit mode, true or false}
              }
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                sx={{ mr: 1 }}
                onClick={() => setEditMode(!editMode)}
              >
                {
                  //padlock icon unlocked or locked based on edit mode}
                  editMode ? <Lock /> : <LockOpen />
                }
              </Button>
            </Tooltip>

            <Tooltip title="Dashboard Settings" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <Settings />
              </Button>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={dashboardOptionsOpen}
              onClose={handleDashboardOptionsClose}
            >
              <MenuItem onClick={handleEditDashboard}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteDashboard}>Delete</MenuItem>
              <MenuItem onClick={handleOpenShareDialog}>Share</MenuItem>
            </Menu>
          </Stack>
        </div>
        {dashboardWidgets.length
          ? dashboardWidgets.map((widget) => (
              <div
                key={widget.id}
                data-grid={{
                  x: widget.x,
                  y: widget.y,
                  w: isMobile ? 12 : widget.w,
                  h: isMobile
                    ? widget.widget_id === 3
                      ? 6
                      : widget.h
                    : widget.h,
                }}
                className="widget"
              >
                <DashboardWidget
                  widget={widget}
                  devices={dashboardDevices}
                  editMode={editMode}
                  dashboardId={id}
                  refreshDashboard={refreshDashboard}
                />
              </div>
            ))
          : null}
      </ReactGridLayout>

      <AddWidgetDialog
        open={addWidgetOpen}
        devices={dashboardDevices}
        dashboardId={id}
        dashboardWidgets={dashboardWidgets}
        handleClose={handleAddWidgetClose}
      />
      <DashboardShare
        open={openShare}
        handleClose={() => setOpenShare(false)}
        dashboard={dashboard}
      />

      <DeleteDashboardConfirmationDialog
        open={deleteDashboardDialogOpen}
        handleClose={() => setDeleteDashboardDialogOpen(false)}
        dashboardId={id}
        refreshDashboard={refreshDashboard}
        dashboardName={dashboard.name}
      />
      <DashboardEditDialog
        open={editDashboardDialogOpen}
        handleClose={() => setEditDashboardDialogOpen(false)}
        dashboard={dashboard}
      />
    </>
  );
}
