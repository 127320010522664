//dialogbase to add organisation
import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, set, useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import setOrganisationMemberRoles from "../../Functions/setOrganisationMemberRoles";

export default function OrganisationMemberEditRolesDialog(props) {
  const { open, onClose, organisationId, userId, member } = props;
  const { t } = useTranslation("common");

  const [admin, setAdmin] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [deleteRole, setDeleteRole] = React.useState(false);
  const [transfer, setTransfer] = React.useState(false);

  React.useEffect(() => {
    if (member) {
      setAdmin(
        member.organisationRoles && member.organisationRoles.admin_permission
          ? member.organisationRoles.admin_permission
          : false
      );
      setEdit(
        member.organisationRoles && member.organisationRoles.edit_permission
          ? member.organisationRoles.edit_permission
          : false
      );
      setAdd(
        member.organisationRoles && member.organisationRoles.add_permission
          ? member.organisationRoles.add_permission
          : false
      );
      setDeleteRole(
        member.organisationRoles && member.organisationRoles.delete_permission
          ? member.organisationRoles.delete_permission
          : false
      );
      setTransfer(
        member.organisationRoles && member.organisationRoles.transfer_permission
          ? member.organisationRoles.transfer_permission
          : false
      );
    }
  }, [member]);

  const handleAdminChange = (e) => {
    setAdmin(e.target.checked);
  };

  const { handleSubmit, control } = useForm({
    reValidateMode: "onBlur",
  });

  const onSubmit = async (data) => {
    const { admin, edit, add, deleteRole, transfer } = data;

    const organisationMemberRole = {
      user_id: userId,
      admin_permission: admin,
      edit_permission: edit,
      add_permission: add,
      delete_permission: deleteRole,
      transfer_permission: transfer,
    };

    await setOrganisationMemberRoles(organisationMemberRole, organisationId);

    updateSnackbarMessage("Organisation Member role updated successfully");

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="organisation-edit-member-role-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="organisation-edit-member-role-dialog-title">
        Edit Member Role
      </DialogTitle>
      <DialogContent>
        <>
          <Controller
            name="admin"
            control={control}
            defaultValue={admin}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      handleAdminChange(e);
                    }}
                  />
                }
                label="Admin Permissions"
              />
            )}
          />

          <Controller
            name="add"
            control={control}
            defaultValue={add || false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value}
                    //if admin is checked, disable add
                    disabled={admin}
                  />
                }
                label="Add Permissions"
              />
            )}
          />

          <Controller
            name="edit"
            control={control}
            defaultValue={edit || false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value}
                    disabled={admin}
                  />
                }
                label="Edit Permissions"
              />
            )}
          />

          <Controller
            name="deleteRole"
            control={control}
            defaultValue={deleteRole || false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value}
                    disabled={admin}
                  />
                }
                label="Delete Permissions"
              />
            )}
          />

          <Controller
            name="transfer"
            control={control}
            defaultValue={transfer || false}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={field.value}
                    disabled={admin}
                  />
                }
                label="Transfer Permissions"
              />
            )}
          />
        </>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onClose}>
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
