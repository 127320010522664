import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import TemperatureGraph from "../Graphs/Temperature";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import HumidityGuague from "../Guages/Humidity";
import HumidityGraph from "../Graphs/Humidity";

export default function DraginoLSN50v2S31(props) {
  let { isShare } = props;

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <TemperatureGuague useIsXL={false} />
        </Grid>
        <Grid item xs={12} md={8} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <HumidityGuague useIsXL={false} />
        </Grid>
        <Grid item xs={12} md={8} sx={{ mb: 1 }}>
          <HumidityGraph useIsXL={false} />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 6}
          xxl={isShare ? 12 : 6}
          sx={{ mb: 1 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} xxl={6} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
