import { useParams } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import ReactGridLayout from "react-grid-layout";

//include these css files for react-grid-layout
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import DashboardWidget from "../components/Widgets/DashboardWidget";
import fetchData from "../API/Fetch";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { store } from "../store";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { CONFIG } from "../config";

export default function DashboardSharePage(props) {
  //get id from url
  const { id } = useParams();
  const theme = useTheme();

  const isMobile = useSelector((state) => state.isMobile);
  const [dashboard, setDashboard] = useState({});

  const themeMode = useSelector((state) => state.themeMode);

  const { t } = useTranslation("common");

  const [dashboardDevices, setDashboardDevices] = useState([]);
  const [dashboardWidgets, setDashboardWidgets] = useState([]);

  const fetchDashboard = useCallback(async () => {
    const result = await axios.get(CONFIG.API_URL + `/dashboard/share/${id}`);

    if (result) {
      setDashboard(result.data);
      setDashboardWidgets(result.data.widgets);

      let devs = await axios.get(
        CONFIG.API_URL + `/dashboard/share/${id}/devices`
      );

      if (devs) {
        setDashboardDevices(devs.data);

        //set to state for use in widgets

        store.dispatch({ type: "devices/update", payload: devs.data });
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchDashboard();

      //interval to fetch data every 30 seconds
      const interval = setInterval(() => {
        fetchDashboard();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [id, fetchDashboard]);

  let width = window.innerWidth;

  //when the window is resized, update the width of the grid
  window.onresize = () => {
    width = window.innerWidth;
  };

  return (
    <Box
      sx={{
        backgroundColor: themeMode && themeMode === "dark" ? "#000" : "#EFEFEF",
        //set height to 100vh
        minHeight: "100vh",
      }}
    >
      <ReactGridLayout
        className="layout"
        rowHeight={30}
        width={width}
        isDraggable={false}
        isResizable={false}
      >
        <div
          key="pageHeader"
          data-grid={{
            x: 0,
            y: 0,
            w: 12,
            h: 1.7,
            static: true,
          }}
          className="widget"
        >
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
          >
            <PageTitle
              theme={theme}
              title={dashboard && dashboard.name ? dashboard.name : "Dashboard"}
              subTitle={
                dashboard && dashboard.description
                  ? dashboard.description
                  : "Dashboard"
              }
            />
            <Tooltip
              title={
                themeMode && themeMode === "dark"
                  ? t("common.switch_to_light_mode")
                  : t("common.switch_to_dark_mode")
              }
              placement="bottom"
            >
              <IconButton
                aria-label="theme mode"
                sx={{ color: "white" }}
                onClick={() => props.handleThemeChange()}
              >
                {themeMode && themeMode === "dark" ? (
                  <Brightness7 />
                ) : (
                  <Brightness4 sx={{ color: theme.palette.primary.main }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </div>
        {dashboardWidgets && dashboardWidgets.length
          ? dashboardWidgets.map((widget) => (
              <div
                key={widget.id}
                data-grid={{
                  x: widget.x,
                  y: widget.y,
                  w: isMobile ? 12 : widget.w,
                  h: isMobile
                    ? widget.widget_id === 3
                      ? 6
                      : widget.h
                    : widget.h,
                }}
                className="widget"
              >
                <DashboardWidget
                  widget={widget}
                  devices={dashboardDevices}
                  dashboardId={id}
                  refreshDashboard={fetchDashboard}
                  isShare={true}
                />
              </div>
            ))
          : null}
      </ReactGridLayout>
    </Box>
  );
}
