import React from "react";
import { Grid } from "@mui/material";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";
import DistanceGraph from "../Graphs/Distance";
import DistanceGuague from "../Guages/FluidLevel";
import DistanceCard from "../Cards/Distance";
import LiquidLevelGraph from "../Graphs/LiquidLevel";
import ButtonPressedCard from "../Cards/ButtonPressed";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import Fluid from "../Guages/Fluid";

export default function DistanceSensor(props) {
  let { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  //check to see if there is a button_pressed field in the deviceHistory.data json string
  //if there is, then we know this is a button tracker and we can show the button press graph and card
  let buttonPressed = false;

  if (deviceHistory.length > 0) {
    let lastData = deviceHistory[deviceHistory.length - 1];
    let jsonData = lastData.data;
    jsonData = JSON.parse(jsonData);
    //check data contains a button_pressed key (it will be true or false value)
    if (
      jsonData.button_pressed &&
      (jsonData.button_pressed === 1 || jsonData.button_pressed === 0)
    ) {
      buttonPressed = true;
    }
  }

  //if button press is true, set the column to 3, otherwise set it to 4
  let buttonPressColumn = buttonPressed ? 3 : 4;

  if (isShare) {
    buttonPressColumn = buttonPressed ? 4 : 6;
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={buttonPressColumn}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={buttonPressColumn}>
          <BatteryStatus />
        </Grid>

        {buttonPressed && (
          <Grid item xs={12} md={buttonPressColumn}>
            <ButtonPressedCard />
          </Grid>
        )}
        {!isShare && (
          <Grid item xs={12} md={buttonPressColumn}>
            <LastAlert />
          </Grid>
        )}
        <Grid item xs={12} md={3} sx={{ my: 1 }}>
          {deviceProfile && deviceProfile.length ? <Fluid /> : <DistanceCard />}
        </Grid>

        {deviceProfile && deviceProfile.length ? (
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <LiquidLevelGraph />
          </Grid>
        ) : (
          <Grid item xs={12} md={9} sx={{ my: 1 }}>
            <DistanceGraph />
          </Grid>
        )}

        <Grid item xs={12} md={!isShare ? 7 : 12}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
