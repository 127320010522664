import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import { useSelector } from "react-redux";
import DeviceDetail from "../Cards/DeviceDetail";
import TemperatureGuague from "../Guages/Temperature";
import { formatDate } from "../../Functions/formatDate";
import { useTranslation } from "react-i18next";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import ThreeTemperaturesGraph from "../Graphs/ThreeTemperatures";

export default function Lsn50v2_d20(props) {
  let { isShare } = props;

  const { t } = useTranslation("common");
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const device = useSelector((state) => state.device);
  const deviceAlerts = useSelector((state) => state.deviceAlerts);

  let deviceFrames;

  if (deviceHistory.length) {
    deviceFrames = deviceHistory[0].data;

    //json decode deviceFrames
    if (deviceFrames) {
      deviceFrames = JSON.parse(deviceFrames);
    }
  }

  return (
    <>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Last Seen"
            content={formatDate(device.time_updated) || "N/A"}
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Work Mode"
            content={
              deviceFrames && deviceFrames.work_mode !== undefined
                ? deviceFrames.work_mode
                : "N/A"
            }
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 4 : 3} sx={{ mb: 1 }}>
          <DeviceDetail
            title="Battery"
            content={
              deviceFrames && deviceFrames.voltageMv !== undefined
                ? convertMvToPercent(deviceFrames.voltageMv) + "%"
                : "N/A"
            }
          />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={3} sx={{ mb: 1 }}>
            <DeviceDetail
              title="Last Alert"
              content={
                deviceAlerts && deviceAlerts.length && deviceAlerts[0].message
                  ? formatDate(deviceAlerts[0].time_created)
                  : t("common.none")
              }
            />
          </Grid>
        )}

        {
          //3 grid items with 3 temperature cards
        }
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <TemperatureGuague
            title="Temperature Red"
            temperatureValue="temp_red"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <TemperatureGuague
            title="Temperature Black"
            temperatureValue="temp_black"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <TemperatureGuague
            title="Temperature White"
            temperatureValue="temp_white"
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 1 }}>
          <ThreeTemperaturesGraph />
        </Grid>

        <Grid
          item
          xs={12}
          md={isShare ? 12 : 6}
          xxl={isShare ? 12 : 6}
          sx={{ mb: 1 }}
        >
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={6} xxl={6} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
