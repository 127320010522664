import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Brightness4,
  Brightness7,
  MenuRounded,
  Support,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, FormControl, Select, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CONFIG } from "../config";
import setSelectedOrganisation from "../Functions/setSelectedOrganisation";
import refreshData from "../Functions/refreshData";

function NavBar(props) {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const theme = useTheme();

  const themeMode = useSelector((state) => state.themeMode);

  const { handleCollapsed } = props;

  const [rotateChevron, setRotateChevron] = React.useState(
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false
  );

  const rotate = rotateChevron ? "rotate(-180deg)" : "rotate(0)";

  const isMobile = useSelector((state) => state.isMobile);
  const organisations = useSelector((state) => state.organisations);

  const [userImage, setUserImage] = React.useState(
    <AccountCircle sx={{ color: "white" }} />
  );

  const handleRotate = () => setRotateChevron(!rotateChevron);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSidebar, setAnchorElSidebar] = React.useState(null);

  const handleCollapsedF = () => {
    if (localStorage.getItem("sidebarCollapsed") === "true") {
      localStorage.setItem("sidebarCollapsed", "false");
    } else {
      localStorage.setItem("sidebarCollapsed", "true");
    }
    handleRotate();
    handleCollapsed();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSidebar = () => {
    setAnchorElSidebar(null);
  };

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user && user.picture) {
        setUserImage(
          <img src={user.picture} className="user-image" alt={user.name} />
        );
      }
    }

    return () => (mounted = false);
  }, [user]);

  return (
    <AppBar position="static">
      <Toolbar>
        <MenuRounded
          onClick={(event) => {
            //if isMobile, open menu, else collapse sidebar
            isMobile
              ? setAnchorElSidebar(event.currentTarget)
              : handleCollapsedF();
          }}
          style={{ transform: rotate, transition: "all 0.2s linear" }}
          sx={{
            mr: 2,
            color: theme.palette.mode === "dark" ? "#8ba1b7" : "#fff",
          }}
        />
        <Menu
          anchorEl={anchorElSidebar}
          keepMounted
          open={!!anchorElSidebar}
          onClose={handleCloseSidebar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Link
            to="/"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.devices")}</Typography>
            </MenuItem>
          </Link>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.dashboards")}</Typography>
            </MenuItem>
          </Link>
          <Link
            to="/action"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.actions")}</Typography>
            </MenuItem>
          </Link>
          <Link
            to="/report"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.reports")}</Typography>
            </MenuItem>
          </Link>
          <Link
            to="/geofence"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.geofences")}</Typography>
            </MenuItem>
          </Link>

          <Link
            to="/organisation"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">
                {t("common.organisations")}
              </Typography>
            </MenuItem>
          </Link>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.contacts")}</Typography>
            </MenuItem>
          </Link>
          <Link
            to="/account"
            style={{ textDecoration: "none", color: "inherit" }}
            onClick={handleCloseSidebar}
          >
            <MenuItem>
              <Typography variant="body1">{t("common.account")}</Typography>
            </MenuItem>
          </Link>
        </Menu>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="h6">{CONFIG.siteTitle}</Typography>
          </Link>
        </Typography>

        {!isMobile && (
          <FormControl variant="standard" sx={{ minWidth: 250, mr: 1 }}>
            {
              // organisation select
              // check selectedOrganisationID is not null or 0 and that organisations is not empty
            }

            <Select
              value={
                organisations && organisations.length
                  ? localStorage.getItem("selectedOrganisationID")
                  : ""
              }
              onChange={(e) => {
                if (e.target.value === "manage") {
                  navigate("/organisation");
                } else {
                  //find the organisation in the organisations array
                  const organisation = organisations.find(
                    (org) => org.id === e.target.value
                  );

                  setSelectedOrganisation(organisation);
                  localStorage.setItem(
                    "selectedOrganisationID",
                    e.target.value
                  );
                  refreshData(user.email, user.sub);
                  //if pageurl contains device, redirect to root
                  if (window.location.href.includes("device")) {
                    navigate("/");
                  }
                }
              }}
              sx={{ color: "white" }}
            >
              {organisations &&
                organisations &&
                organisations.length > 0 &&
                organisations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.name}
                  </MenuItem>
                ))}
              <MenuItem value={"manage"}>
                <em>{t("common.manage_organisations")}</em>
              </MenuItem>
            </Select>
          </FormControl>
        )}

        {
          // dark mode toggle icon
        }
        <Tooltip
          title={
            themeMode && themeMode === "dark"
              ? t("common.switch_to_light_mode")
              : t("common.switch_to_dark_mode")
          }
          placement="bottom"
        >
          <IconButton
            aria-label="theme mode"
            sx={{ color: "white" }}
            onClick={() => props.handleThemeChange()}
          >
            {themeMode && themeMode === "dark" ? (
              <Brightness7 />
            ) : (
              <Brightness4 />
            )}
          </IconButton>
        </Tooltip>
        {CONFIG.support_url && (
          <Tooltip title={t("common.support")} placement="bottom" arrow>
            <IconButton
              aria-label="support"
              sx={{ color: "white" }}
              onClick={() => {
                window.open(CONFIG.support_url, "_blank");
              }}
            >
              <Support />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={t("common.account")} placement="bottom" arrow>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            {userImage}
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {user && user.email && <MenuItem disabled>{user.email}</MenuItem>}
          <Divider />
          <MenuItem
            onClick={() => {
              //redirect to account page
              navigate("/account");
            }}
          >
            {t("common.account")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              navigate("/organisation");
            }}
          >
            {t("common.manage_organisations")}
          </MenuItem>
          <Divider />
          {CONFIG.feedback_enabled && (
            <MenuItem
              onClick={() => {
                //redirect to feedback page
                window.location.href = CONFIG.feedback_url;
              }}
            >
              {t("common.feedback")}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              //delete token from local storage
              localStorage.removeItem("access_token");
              localStorage.removeItem("selectedOrganisationID");
              logout({
                returnTo: window.location.origin,
              });
            }}
          >
            {t("common.logout")}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
