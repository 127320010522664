import { Box, Typography, useTheme } from "@mui/material";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import convertPercentToColour from "../../Functions/convertPercentToColour";
import formatTemperature from "../../Functions/formatTemperature";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import formatSpeed from "../../Functions/formatSpeed";
import WidgetCard from "./WidgetCard";

library.add(fas);

export default function IconCardWidget(props) {
  const { widget, dashboardId, editMode, refreshDashboard, isShare } = props;

  const devices = useSelector((state) => state.devices);

  const theme = useTheme();

  let value = "N/A";
  let icon = "";
  let iconSize = "24";
  let title = "Icon Card";
  let formatter = "none";
  let device = null;
  let date = "N/A";
  let iconUnit = "";
  let fontSize = "4rem";

  if (widget && widget.field) {
    let field = JSON.parse(widget.field);

    let deviceId = field["Value Field"];
    let deviceField = field["Field"];
    let iconField = field["Icon"];
    let iconSizeField = field["Icon Size"];
    let iconformatter = field["Formatter"];
    let titleField = field["Title"];
    iconUnit = field["Unit"];
    let textsize = field["TextSize"];

    if (textsize) {
      fontSize = textsize + "rem";
    }

    //problem, icons come in in camel case, but the library uses fa-question-mark
    // split on a capital letter and add a dash before it and make it lowercase
    if (iconField) {
      //first split on capital letters
      let iconArray = iconField.split(/(?=[A-Z])/);

      //then join with a dash
      iconField = iconArray.join("-").toLowerCase();
    }
    let device = devices.find(
      (device) => parseInt(device.id) === parseInt(deviceId)
    );

    if (device) {
      let data = JSON.parse(device.last_data);

      if (data) {
        value = data[deviceField];
      }

      if (device) {
        date = device.time_updated ? device.time_updated : new Date();
      }
    }

    title = titleField;
    icon = iconField;
    iconSize = iconSizeField;
    if (iconformatter) {
      formatter = iconformatter;
    }

    //if theres a formatter, and its temperature, set the icon unit to C
    if (formatter && formatter === "temperature") {
      //check temperature unit in local storage
      let temperature_unit = localStorage.getItem("temperature_unit");

      //if temperature unit is null, set to C with degrees symbol
      //degree symbol is : &deg; or
      if (!temperature_unit) {
        localStorage.setItem("temperature_unit", "C");
        temperature_unit = "C";
      }

      iconUnit = "°" + temperature_unit;
    }
  }

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={date}
    >
      <FontAwesomeIcon
        icon={icon}
        size={iconSize}
        color={theme.palette.primary.main}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent:
            iconUnit && iconUnit !== "" ? "space-between" : "flex-end",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: fontSize ? fontSize : "4rem",
            lineHeight: fontSize ? fontSize : "4rem",
            alignSelf: "center",
            mr: 1,
          }}
          color={
            formatter && formatter === "battery"
              ? convertPercentToColour(value)
              : null
          }
        >
          {formatter && formatter === "temperature"
            ? formatTemperature(value, true)
            : null}

          {formatter === "none" ? (value ? value : "N/A") : null}

          {formatter === "round" ? Math.round(value) : null}

          {formatter === "speed"
            ? value !== null && value !== undefined
              ? formatSpeed(value)
              : "N/A"
            : null}

          {formatter && formatter === "battery"
            ? convertMvToPercent(
                value,
                device && device.deviceBrand ? device.deviceBrand : null
              )
            : null}
        </Typography>
        {iconUnit ? <Typography variant="p">{iconUnit}</Typography> : null}
      </Box>
    </WidgetCard>
  );
}
