import { useTheme } from "@emotion/react";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
//import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";
import fetchData from "../../API/Fetch";
import WidgetCard from "./WidgetCard";
import moment from "moment";

export default function GraphWidget(props) {
  //const devices = useSelector((state) => state.devices);
  const { widget, isShare, editMode, dashboardId, refreshDashboard } = props;
  const [aspect, setAspect] = React.useState(1);
  const theme = useTheme();

  const [title, setTitle] = React.useState("");
  const [values, setValues] = React.useState({});
  const [history, setHistory] = React.useState({});
  // const [date, setDate] = React.useState("N/A");

  useEffect(() => {
    //use widget.h and widget.w to calculate aspect ratio
    setAspect((widget.h * 2.5) / widget.w);

    async function fetchValues() {
      let daysOfData = 1;
      if (widget && widget.field) {
        let field = JSON.parse(widget.field);
        setTitle(field["Title"]);

        //valeFields is an array of values in the format deviceid,field
        let valueFields = field["Values"];

        //fetch each device, get a history of each for 24 hours, and then add that value to the graph
        let history = [];

        //create a unixtimestamp for the startDate and endDate
        let endDate = Math.floor(Date.now() / 1000);
        let startDate = endDate - 60 * 60 * 24 * daysOfData;

        valueFields.forEach(async (valueField) => {
          let [deviceId, field] = valueField.split(",");

          //get todays

          //now get the device history, get the data field, fetch the value for each history item.
          let deviceHistory = await fetchData(
            `/frame/${deviceId}/${startDate}/${endDate}`
          );

          //we now want to extract the data field from each history item and the date
          if (deviceHistory.length > 0) {
            let data = deviceHistory.map((historyItem) => {
              let data = JSON.parse(historyItem.data);
              //date is in format "2024-08-11T13:39:11.562195" set the seconds and milliseconds to 0

              let date = new Date(historyItem.time_created);
              date.setSeconds(0);
              date.setMilliseconds(0);

              //format the date back to 2024-08-11T13:39:11.562195
              date = date.toISOString();

              return {
                Date: date,
                [field]: data[field],
              };
            });

            history = history.concat(data);
          }
        });

        //merge any fields that have the same date
        let merged = [];

        history.forEach((historyItem) => {
          let date = historyItem.Date;
          let found = merged.find((item) => item.Date === date);
          if (found) {
            found = { ...found, ...historyItem };
          } else {
            merged.push(historyItem);
          }
        });

        //sort the history by date
        history.sort((a, b) => {
          return new Date(a.Date) - new Date(b.Date);
        });

        history = merged;

        setHistory(history);
        setValues(valueFields.map((valueField) => valueField.split(",")[1]));
      }
    }
    fetchValues();
  }, [widget]);

  return (
    <WidgetCard
      widget={widget}
      refreshDashboard={refreshDashboard}
      editMode={editMode}
      isShare={isShare}
      dashboardId={dashboardId}
      title={title}
      date={moment().toISOString()}
    >
      {
        <ResponsiveContainer aspect={aspect}>
          <AreaChart data={history}>
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            {
              //add a y axis for each value
            }
            {values &&
              values.length > 0 &&
              values.map((value, index) => {
                return (
                  <YAxis
                    key={index}
                    dataKey={value}
                    type={"number"}
                    domain={["auto", "auto"]}
                  />
                );
              })}
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
            />
            {
              //add an area for each value
            }
            {values &&
              values.length > 0 &&
              values.map((value, index) => {
                return (
                  <Area
                    key={index}
                    yAxisId="1"
                    dataKey={value}
                    stroke={theme.palette.primary.main}
                    animationDuration={300}
                    fill="url(#Temperature)"
                    strokeWidth={2}
                    dominantBaseline={"middle"}
                  />
                );
              })}
          </AreaChart>
        </ResponsiveContainer>
      }
    </WidgetCard>
  );
}
