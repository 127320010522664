import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Stack,
  TextField,
  Typography,
  Grid,
  MenuItem,
} from "@mui/material";
import React from "react";
import fetchData from "../API/Fetch";
import { CONFIG } from "../config";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";

function Account(props) {
  const { handleThemeChange } = props;

  setTitle("My Account");

  const theme = useTheme();

  const user = useSelector((state) => state.user);

  const { user: auth0User } = useAuth0();

  const [temperatureUnit, setTemperatureUnit] = React.useState(
    localStorage.getItem("temperature_unit") || "C"
  );

  const [speedUnit, setSpeedUnit] = React.useState(
    localStorage.getItem("speed_unit") || "kmph"
  );

  const [dateFormat, setDateFormat] = React.useState(
    localStorage.getItem("date_format") || CONFIG.defaultDateFormat
  );

  const [timeFormat, setTimeFormat] = React.useState(
    localStorage.getItem("time_format") || "HH:mm:ss"
  );

  const [currency, setCurrency] = React.useState(
    localStorage.getItem("currency") || "GBP"
  );

  const [refresh_interval, setRefreshInterval] = React.useState(
    localStorage.getItem("refresh_interval") || 5
  );

  const [themeMode, setThemeMode] = React.useState(
    localStorage.getItem("theme") || CONFIG.defaultTheme
  );

  const [apiUsage, setApiUsage] = React.useState(0);
  const [apiUsageHistory, setApiUsageHistory] = React.useState([{}]);

  //set page title
  React.useEffect(() => {
    setTitle("My Account");

    //get api usage
    fetchData(`/user/api-key-usage`).then((data) => {
      if (data === null) {
        return;
      }
      setApiUsage(data && data.count ? data.count : 0);
    });

    //get api usage history
    fetchData(`/user/api-key-usage-history`).then((data) => {
      if (!data) {
        return;
      }

      //check length
      if (data.length === 0) {
        return;
      }

      let newData = [];
      //merge month and year into one "Date"
      data.forEach((item) => {
        newData.push({
          date: item.month + "/" + item.year,
          month: item.month,
          year: item.year,
          count: item.count || 0,
        });
      });

      //order by date, ascending
      newData.sort((a, b) => {
        return new Date(a.year, a.month) - new Date(b.year, b.month);
      });

      setApiUsageHistory(newData);
    });
  }, []);

  const openCardCaptureSession = () => {
    fetchData(`/billing/card-capture-session`).then((data) => {
      //redirect to url
      window.location.href = data.url;
    });
  };

  const openBillingPortal = () => {
    fetchData(`/billing/portal`).then((data) => {
      window.location.href = data.url;
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mb: 1, mt: 1 }}>
        <PageTitle
          title={auth0User && auth0User.email ? auth0User.email : "My Account"}
          theme={theme}
          subTitle={"Account Settings"}
        />
      </Grid>
      {CONFIG.billing_enabled && (
        <Grid
          item
          xs={12}
          md={12}
          lg={3}
          xl={3}
          sx={{ mb: 1, display: "flex" }}
        >
          <Card
            style={{
              display: "flex",
              //full width
              width: "100%",
              flexDirection: "column",
            }}
          >
            <CardHeader
              title="Billing Settings"
              action={
                <Typography variant="h6">
                  {user && user.stripe_billing_status === "active"
                    ? user.device_limit + " Device Limit"
                    : "Inactive"}
                </Typography>
              }
            />
            <CardContent>
              <Stack direction="column" spacing={1}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => openCardCaptureSession()}
                >
                  Register/Update Card
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => openBillingPortal()}
                >
                  Billing Portal (Manage Billing)
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => openBillingPortal()}
                >
                  View Invoices
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    //redirect to billing
                    window.location.href = "/billing/limit-reached";
                  }}
                >
                  Increase Device Limit
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={12}
        lg={!CONFIG.billing_enabled ? 12 : 9}
        xl={!CONFIG.billing_enabled ? 12 : 9}
        sx={{ mb: 1 }}
      >
        <Card>
          <CardHeader title="Settings" />

          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    select
                    label="Temperature Unit"
                    value={temperatureUnit}
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      localStorage.setItem("temperature_unit", e.target.value);
                      setTemperatureUnit(e.target.value);
                      updateSnackbarMessage(
                        e.target.value === "F"
                          ? "Temperature unit updated successfully to Fahrenheit"
                          : "Temperature unit updated successfully to Celsius"
                      );
                    }}
                  >
                    <MenuItem value="F">Fahrenheit</MenuItem>
                    <MenuItem value="C">Celsius</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    select
                    label="Speed Unit"
                    value={speedUnit}
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      localStorage.setItem("speed_unit", e.target.value);
                      setSpeedUnit(e.target.value);
                      updateSnackbarMessage(
                        e.target.value === "mph"
                          ? "Speed unit updated successfully to Miles Per Hour"
                          : "Speed unit updated successfully to Kilometers Per Hour"
                      );
                    }}
                  >
                    <MenuItem value="kmph">Kilometers Per Hour</MenuItem>
                    <MenuItem value="mph">Miles Per Hour</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    select
                    label="Currency"
                    value={currency || "GBP"}
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      localStorage.setItem("currency", e.target.value);
                      setCurrency(e.target.value);
                      updateSnackbarMessage(
                        "Currency updated successfully to " + e.target.value
                      );
                    }}
                  >
                    <MenuItem value="GBP">GBP</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    label="Theme Mode"
                    value={themeMode}
                    select
                    variant="outlined"
                    onChange={(e) => {
                      setThemeMode(e.target.value);
                      handleThemeChange(e.target.value);
                    }}
                  >
                    <MenuItem value="light">Light</MenuItem>
                    <MenuItem value="dark">Dark</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    label="Date Format"
                    value={dateFormat}
                    select
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      setDateFormat(e.target.value);
                      localStorage.setItem("date_format", e.target.value);
                      updateSnackbarMessage(
                        "Date format updated successfully to " + e.target.value
                      );
                    }}
                  >
                    <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
                    <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
                    <MenuItem value="YYYY/MM/DD">YYYY/MM/DD</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    label="Time Format"
                    value={timeFormat}
                    select
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      setTimeFormat(e.target.value);
                      localStorage.setItem("time_format", e.target.value);
                      updateSnackbarMessage(
                        "Time format updated successfully to " + e.target.value
                      );
                    }}
                  >
                    {
                      //24HR or 12HR with AM/PM and with or without seconds
                    }
                    <MenuItem value="HH:mm">24HR (HH:mm)</MenuItem>
                    <MenuItem value="HH:mm:ss">24HR (HH:mm:ss)</MenuItem>
                    <MenuItem value="h:mm:ssa">12HR (h:mm:ssa)</MenuItem>
                    <MenuItem value="h:mma">12HR (h:mma)</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={3} sx={{ mb: 1 }}>
                <FormControl fullWidth>
                  <TextField
                    sx={{ mb: 1 }}
                    label="Refresh Interval"
                    value={refresh_interval}
                    select
                    variant="outlined"
                    onChange={(e) => {
                      //save in local storage
                      localStorage.setItem("refresh_interval", e.target.value);
                      updateSnackbarMessage(
                        "Refresh interval updated successfully to " +
                          e.target.value
                      );
                      setRefreshInterval(e.target.value);
                    }}
                  >
                    <MenuItem value="1">1 minute</MenuItem>
                    <MenuItem value="2">2 minutes</MenuItem>
                    <MenuItem value="4">4 minutes</MenuItem>
                    <MenuItem value="5">5 minutes</MenuItem>
                    <MenuItem value="10">10 minutes</MenuItem>
                    <MenuItem value="15">15 minutes</MenuItem>
                    <MenuItem value="30">30 minutes</MenuItem>
                    <MenuItem value="60">60 minutes</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {CONFIG.api_key_enabled && (
        <>
          <Grid item xs={12} md={12} lg={3} xl={3} sx={{ mb: 1 }}>
            <Card sx={{ height: "100%" }}>
              <CardHeader title="Location Engine API Settings" />
              <CardContent sx={{ height: "100%" }}>
                {user && user && user.api_key && (
                  <FormControl sx={{ mt: 1 }} fullWidth>
                    <TextField
                      sx={{ mb: 1 }}
                      label="API Key"
                      value={user && user ? user.api_key : ""}
                      //password with show/hide button
                      type="password"
                      //on click swap between text and password
                      onClick={(e) => {
                        e.target.type === "password"
                          ? (e.target.type = "text")
                          : (e.target.type = "password");
                      }}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                )}
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                  <Button
                    disabled={
                      //check user has a card on file
                      user && user && user.stripe_customer_id ? false : true
                    }
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      fetchData(`/user/generate-api-key`).then((data) => {
                        //if null, error
                        if (data === null) {
                          updateSnackbarMessage(
                            "Error generating API key, please try again later"
                          );
                          return;
                        }

                        updateSnackbarMessage("API key rolled successfully");
                      });
                    }}
                  >
                    {user && user && user.api_key
                      ? " Roll API Key"
                      : "Generate API Key"}
                  </Button>
                  {user && user && user.api_key && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        //copy to clipboard
                        navigator.clipboard.writeText(
                          user && user && user.api_key
                        );
                        updateSnackbarMessage("API key copied to clipboard");
                      }}
                    >
                      Copy API Key
                    </Button>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          {user.api_key && (
            <Grid item xs={12} md={12} lg={9} xl={9} sx={{ mb: 1 }}>
              <Card>
                <CardHeader
                  title="Location Engine API Usage"
                  action={
                    <Typography variant="h6">{apiUsage} API Calls</Typography>
                  }
                />
                <CardContent>
                  {
                    //check we have usage history
                    user &&
                      user &&
                      user.api_key &&
                      apiUsageHistory.length > 0 && (
                        <>
                          <Typography sx={{ mb: 1 }}>
                            <strong>Usage History:</strong>
                          </Typography>

                          <ResponsiveContainer width="100%" height={225}>
                            <AreaChart
                              data={apiUsageHistory}
                              margin={{
                                top: 0,
                                right: 30,
                                left: -15,
                                bottom: 0,
                              }}
                            >
                              <defs>
                                <linearGradient
                                  id="APIUsage"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="5%"
                                    stopColor={theme.palette.primary.main}
                                    stopOpacity={0.8}
                                  />
                                  <stop
                                    offset="95%"
                                    stopColor={theme.palette.primary.main}
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <XAxis
                                dataKey="date"
                                type={"category"} //format date to be more readable
                              />
                              <YAxis dataKey="count" yAxisId="1" />
                              <Tooltip
                                contentStyle={{
                                  backgroundColor:
                                    theme.palette.background.default,
                                }}
                                cursor={false}
                                formatter={(value, name) => {
                                  return [value, "API Calls"];
                                }}
                                labelFormatter={(value) => {
                                  return value;
                                }}
                              />

                              <Area
                                yAxisId="1"
                                dataKey="count"
                                stroke={theme.palette.primary.main}
                                animationDuration={300}
                                fill="url(#APIUsage)"
                                strokeWidth={2}
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                        </>
                      )
                  }
                </CardContent>
              </Card>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default withAuthenticationRequired(Account);
