import React from "react";
import { Grid } from "@mui/material";
import DeviceActionLogs from "../Cards/Alerts";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import FrameList from "../FrameList";
import WaterLeakDetectedCard from "../Cards/WaterLeak";
import DevicePageCard from "../Cards/DevicePageCard";
import formatTemperature from "../../Functions/formatTemperature";

export default function VutilityHotdrop(props) {
  let { isShare } = props;

  /*
  Fields: 

      uplink.decoded.payload.amp_hour_accumulation = amp_hour_accumulation
    uplink.decoded.payload.average_amps = average_amps
    uplink.decoded.payload.maximum_amps = maximum_amps
    uplink.decoded.payload.minimum_amps = minimum_amps
    uplink.decoded.payload.capacitor_voltage = capacitor_voltage
    uplink.decoded.payload.temperature_celsius = temperature_celsius

    */

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
              <LastAlert />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            amp_hour_accumulation
            */}
          <DevicePageCard
            value={"amp_hour_accumulation"}
            unit={"Ah"}
            title={"Amp Hour Accumulation"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            average_amps
            */}
          <DevicePageCard
            value={"average_amps"}
            unit={"A"}
            title={"Average Amps"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            maximum_amps
            */}
          <DevicePageCard
            value={"maximum_amps"}
            unit={"A"}
            title={"Maximum Amps"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            minimum_amps
            */}
          <DevicePageCard
            value={"minimum_amps"}
            unit={"A"}
            title={"Minimum Amps"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            capacitor_voltage
            */}
          <DevicePageCard
            value={"capacitor_voltage"}
            unit={"V"}
            title={"Capacitor Voltage"}
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          {/*
            temperature_celsius
            */}
          <DevicePageCard
            value={"temperature_celsius"}
            unit={"°C"}
            title={"Temperature Celsius"}
            formatter={formatTemperature}
          />
        </Grid>

        <Grid item xs={12} md={!isShare ? 8 : 12} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
