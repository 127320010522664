import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function ConductivityGuague(props) {
  const [conductivity, setConductivity] = React.useState(0);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(100);

  const { value, title } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      let conductivity;

      if (value) {
        conductivity = jsonData[value];
      } else {
        conductivity = jsonData.conductivity;
      }

      //check if value is an array, if it is get the first value
      if (Array.isArray(conductivity)) {
        conductivity = conductivity[0];
      }

      //get conductivity
      setConductivity(conductivity);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.deviceProfile, value]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={title ? title : "Conductivity"}
        action={<Typography variant="h5">{conductivity + "EC"}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={conductivity}
        minValue={minValue}
        maxValue={maxValue}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          type="conductivity"
          value={value ? value : "conductivity"}
          unit="%"
        />
      </Box>
    </Card>
  );
}
