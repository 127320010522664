import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function Rssi(props) {
  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);

  const [rssi, setRssi] = React.useState("N/A");

  useEffect(() => {
    if (deviceHistory && deviceHistory.length > 0) {
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      jsonData = JSON.parse(jsonData);
      setRssi(jsonData.rssi);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Signal Quality
          </Typography>
        </Box>
        <Tooltip title={rssi !== "N/A" ? "Rssi: " + rssi : "Rssi: N/A"}>
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {rssi !== null ? rssi : "N/A"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
