import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function C02Graph(props) {
  const [c02History, setC02History] = React.useState([]);

  const { c02Value } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let co2 = 0;

        if (c02Value) {
          co2 = jsonData[c02Value];
        } else {
          co2 = jsonData.co2;
        }

        //if its an array, push first value
        if (Array.isArray(co2)) {
          tempHistory.push({
            Date: time_created,
            C02: co2[0],
          });
        } else {
          tempHistory.push({
            Date: time_created,
            C02: co2,
          });
        }
      });
      setC02History(tempHistory);
    } catch (error) {}
  }, [deviceHistory, c02Value]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="C02 PPM History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={c02History}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="C02" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value + "ppm", name];
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="C02"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
