import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React from "react";
import { formatDate } from "../../Functions/formatDate";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function LastSeen(props) {
  const theme = useTheme();

  const device = useSelector((state) => state.device);
  let last_heard = device.time_updated || "N/A";

  const deviceHistory = useSelector((state) => state.deviceHistory);

  if (last_heard === "N/A") {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];

      last_heard = lastData.time_created;
    }
  }

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Last Heard
          </Typography>
        </Box>
        <Tooltip
          title={
            last_heard !== "N/A"
              ? "Last Heard: " + formatDate(last_heard)
              : "Last Heard: N/A"
          }
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "1.2rem",
              color:
                //if last_heard is more than 48 hours ago then red, else green
                last_heard !== "N/A"
                  ? new Date() - new Date(last_heard) > 172800000
                    ? "#f44336"
                    : "#4caf50"
                  : "#000",
            }}
          >
            {last_heard && last_heard.length ? formatDate(last_heard) : "N/A"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
