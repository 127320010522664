import { Grid } from "@mui/material";
import React from "react";
import LastSeen from "../Cards/LastSeen";
import BatteryStatus from "../Cards/BatteryStatus";
import LastAlert from "../Cards/LastAlert";
import TemperatureGuague from "../Guages/Temperature";
import DeviceActionLogs from "../Cards/Alerts";
import FrameList from "../FrameList";
import SoilMoistureGuague from "../Guages/SoilMoisture";
import ConductivityGuague from "../Guages/Conductivity";
import GraphBase from "../Graphs/GraphBase";

export default function DraginoLES01(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={!isShare ? 4 : 6} sx={{ mb: 1 }}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4} sx={{ mb: 1 }}>
            <LastAlert />
          </Grid>
        )}

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <TemperatureGuague
            temperatureValue="soil_temperature"
            title="Soil Temperature"
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <SoilMoistureGuague value="soil_moisture" title="Soil Moisture" />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <ConductivityGuague
            value="soil_conductivity"
            title="Soil Conductivity"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <GraphBase
            value="soil_temperature"
            title="Soil Temperature"
            unit={
              localStorage.getItem("temperature_unit") === "F" ? "°F" : "°C"
            }
            type="temperature"
          />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <GraphBase value="soil_moisture" title="Soil Moisture" unit="" />
        </Grid>

        <Grid item xs={12} md={4} sx={{ mb: 1 }}>
          <GraphBase
            value="soil_conductivity"
            title="Soil Conductivity"
            unit=""
          />
        </Grid>

        <Grid item xs={12} md={isShare ? 12 : 6} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={6}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
