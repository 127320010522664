import { MoreVert } from "@mui/icons-material";
import { Box, Card, IconButton } from "@mui/material";
import React from "react";
import WidgetEditOptions from "../widgetEditOptions";

export default function ImageWidget(props) {
  const { widget, editMode, dashboardId, refreshDashboard } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [widgetMenuOpen, setWidgetMenuOpen] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setWidgetMenuOpen(false);
  };

  if (!widget.field) {
    return <div>Widget has no field</div>;
  }
  let field = JSON.parse(widget.field);
  let url = field["URL"];
  let title = field["Title"];
  let fit = field["Fit"];

  if (editMode) {
    return (
      <Card
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Image Widget</p>
      </Card>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {
          //if its not edit mode, float a settings icon in the top right corner

          !editMode && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                right: "0",
                padding: "8px",
              }}
            >
              <IconButton
                sx={{
                  p: 0,
                }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setWidgetMenuOpen(true);
                }}
              >
                <MoreVert />
              </IconButton>
            </Box>
          )
        }

        <img
          src={url}
          alt={title}
          style={{
            width: "100%",
            height: "100%",
            objectFit: fit ? fit : "contain",
          }}
        />
      </Box>
      <WidgetEditOptions
        anchorEl={anchorEl}
        handleClose={handleClose}
        widget={widget}
        widgetMenuOpen={widgetMenuOpen}
        dashboardId={dashboardId}
        refreshDashboard={refreshDashboard}
      />
    </>
  );
}
