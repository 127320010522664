import React from "react";

import FrameList from "../FrameList";
import { Grid } from "@mui/material";
import TimelineCard from "../Cards/timeline";
import DeviceInfo from "../Cards/DeviceInfo";
import { useSelector } from "react-redux";
import DeviceActionLogs from "../Cards/Alerts";
import TemperatureGuague from "../Guages/Temperature";
import TemperatureGraph from "../Graphs/Temperature";

export default function Tracker(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { isShare } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);

  return (
    <>
      {
        //history and graphs
      }
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} md={12} sx={{ mb: 1 }}>
          <TimelineCard />
        </Grid>

        {isMobile && (
          <Grid item xs={12} md={12} sx={{ mb: 1 }}>
            <DeviceInfo />
          </Grid>
        )}

        {
          //check if has a temperature field
          deviceHistory.length > 0 &&
            deviceHistory[deviceHistory.length - 1].data.includes(
              "temperature"
            ) && (
              <>
                <Grid item xs={12} md={3} xxl={3} sx={{ mb: 1 }}>
                  <TemperatureGuague />
                </Grid>

                <Grid item xs={12} md={9} xxl={9} sx={{ mb: 1 }}>
                  <TemperatureGraph useIsXL={false} />
                </Grid>
              </>
            )
        }

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FrameList />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={6}>
            <DeviceActionLogs />
          </Grid>
        )}
      </Grid>
    </>
  );
}
